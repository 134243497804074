export default {
    title: 'UX/UI Fundamentals',
    desc: `Explore foundations of user experience & user interface design.
    \n
    UX stands for "user experience" and refers to the overall experience that a person has when using a product or service. This includes how easy it is to use, how enjoyable the experience is, and how well it meets the user's needs and expectations.
    \n
UI stands for "user interface" and refers to the visual and interactive elements of a product or service that allow the user to interact with it. This includes things like buttons, menus, text fields, and other visual elements that make up the interface.
\n
In simple terms, UX focuses on how a product or service feels to use, while UI focuses on how it looks and how the user interacts with it. Both UX and UI are important in creating a positive user experience.
    `,
    1: {
        title: 'Understanding Users',
        desc: `Human-centered design (HCD), which is also called user-centered design, is an approach to problem-solving that is commonly used in design frameworks. To create solutions, HCD uses the human perspective in all steps of the problem-solving process.
        \n
        How does HCD work in the real world? Typically, you and your team will observe a problem within a particular context, brainstorm and ideate, conceptualize, and develop a solution to implement.`,
        links: [
            {
                title: 'Introduction to Human-Centered Design',
                url: 'https://acumenacademy.org/course/design-kit-human-centered-design',
            },
            {
                title: 'The Stanford d.school Virtual Crash Course',
                url: 'https://dschool.stanford.edu/resources/get-started-with-design'
            }
        ]
    },
    2: {
        title: 'Journaling',
        desc: `A large part of building your practice as a designer is about how you approach problems and how you think. To help clarify your thought process for yourself and to share your thought process, use journaling to take notes, link to work that you have done, compile external resources that you've found helpful, and keep any other records that you would like to reference later. `,
        links: [
            {
                title: 'Using Notion as your daily journal',
                url: 'https://www.youtube.com/watch?v=1Z06fqYhNgM',
            },
            {
                title: 'A Guide To Daily Journaling',
                url: 'https://www.youtube.com/watch?v=HkEuM_GLG6k',
            }
        ]
    },
    3: {
        title: 'Design Process',
        desc: 'The design process is a systematic approach used by designers to solve problems and create solutions that meet user needs. It involves several stages, from understanding the problem to prototyping and testing solutions.',
        links: [
            {
                title: 'The Design Process: What is It and How to Use It',
                url: 'https://www.interaction-design.org/literature/article/the-design-process-what-is-it-and-how-to-use-it'
            },
            {
                title: 'User Experience Design Process',
                url: 'https://uxplanet.org/user-experience-design-process-d91df1a45916'
            }
        ]
    },
    4: {
        title: 'Double Diamond Process',
        desc: 'The Double Diamond process is a design thinking approach that involves four stages: Discover, Define, Develop, and Deliver. The process helps designers explore ideas, narrow down options, and refine their solutions.',
        links: [
            {
                title: 'The Double Diamond: Strategy and Innovation',
                url: 'https://www.designcouncil.org.uk/our-work/skills-learning/tools-frameworks/framework-for-innovation-design-councils-evolved-double-diamond/'
            },
            {
                title: 'The Double Diamond Design Process',
                url: 'https://en.wikipedia.org/wiki/Double_Diamond_(design_process_model)'
            }
        ]
    },
    5: {
        title: 'IDEO Process',
        desc: 'The IDEO process is a human-centered design approach used by the design firm IDEO. It involves three stages: Inspiration, Ideation, and Implementation. The process emphasizes empathy, collaboration, and iterative design.',
        links: [
            {
                title: 'The IDEO Design Thinking Process',
                url: 'https://www.ideou.com/pages/design-thinking'
            }
        ]
    },
    6: {
        title: 'Empathize',
        desc: 'Empathy is the first stage of the design thinking process, and involves understanding the user\'s needs and feelings. This stage often involves research, such as user interviews and observations.',
        links: [
            {
                title: 'Empathy Mapping: The First Step in Design Thinking',
                url: 'https://www.edutopia.org/blog/teaching-empathy-through-design-thinking-rusul-alrubail#:~:text=1.-,Empathize,problem%2C%20circumstance%2C%20or%20situation.'
            },
            {
                title: 'What is Empathize?',
                url: 'https://www.interaction-design.org/literature/topics/empathize'
            }
        ]
    },
    7: {
        title: 'Define',
        desc: 'The Define stage of the design thinking process involves synthesizing the insights gathered in the Empathize stage to form a problem statement or design challenge. This stage helps designers clarify the problem they are trying to solve.',
        links: [
            {
                title: 'DSchool: Get Started with Design Thinking',
                url: 'https://dschool.stanford.edu/resources/getting-started-with-design-thinking'
            },
            {
                title: 'The Define Stage of Design Thinking',
                url: 'https://www.interaction-design.org/literature/article/stage-2-in-the-design-thinking-process-define-the-problem-and-interpret-the-results'
            }
        ]
    },
    8: {
        title: 'Ideate',
        desc: 'Ideation is the process of generating a large number of ideas in a short amount of time. The goal of ideation is to come up with as many solutions as possible, without worrying about their feasibility or practicality. Ideation is a crucial step in the design process, as it allows designers to explore a wide range of options and possibilities.',
        links: [
            {
                title: 'The Ultimate Guide to Ideation',
                url: 'https://www.found.co.uk/blog/design-thinking-the-ultimate-guide-to-ideation/#.ZBQGGOxBy3I',
            },
            {
                title: '10 Ideation Techniques for Designers',
                url: 'https://www.interaction-design.org/literature/article/introduction-to-the-essential-ideation-techniques-which-are-the-heart-of-design-thinking',
            }
        ]
    },
    9: {
        title: 'Prototype',
        desc: 'Prototyping is the process of creating a simplified, preliminary version of a design. Prototyping allows designers to test and refine their ideas before investing a lot of time and money into building the final product. There are many different types of prototypes, from simple sketches to interactive digital models.',
        links: [
            {
                title: 'The Ultimate Guide to Prototyping',
                url: 'https://uxmag.com/articles/the-ultimate-guide-to-prototyping',
            },
            {
                title: 'A Beginner’s Guide to Prototyping in UX Design',
                url: 'https://www.toptal.com/designers/prototyping/guide-to-prototype-design',
            }
        ]
    },
    10: {
        title: 'Test',
        desc: 'Testing is the process of evaluating a design to see how well it meets the needs and expectations of users. Testing can take many forms, from simple surveys and interviews to more complex usability tests. The goal of testing is to identify any problems or issues with the design, so they can be addressed before the final product is built.',
        links: [
            {
                title: 'The Ultimate Guide to Usability Testing',
                url: 'https://influencermarketinghub.com/usability-testing/',
            },
            {
                title: 'A Beginner’s Guide to User Testing',
                url: 'https://blog.hubspot.com/marketing/usability-testing',
            }
        ]
    },
    11: {
        title: 'Play Game',
        desc: 'Try out our game based on the 5 steps in Design Thinking and see if you\'ve got a keen interest in UX/UI Design.',
        links: [
            {
                title: 'View Game ⭐',
                url: 'https://www.uxuiopen.com/play/',
            }
        ]
    },
    12: {
        title: 'Visual Design Principles',
        desc: 'Visual design principles refer to a set of guidelines and best practices that help designers create aesthetically pleasing and effective visual designs. These principles include concepts such as balance, contrast, color, typography, and more.',
        links: [
            {
                title: '10 Principles of Good Design',
                url: 'https://readymag.com/shuffle/dieter-rams/'
            },
            {
                title: '5 Basic Principles of Graphic Design',
                url: 'https://www.makeuseof.com/tag/5-basic-principles-graphic-design-take-granted-everyday/'
            }
        ]
    },
    13: {
        title: 'Accessible Design',
        desc: 'Accessible design is the practice of designing products, services, and environments that can be used by people with disabilities. This includes designing for visual, auditory, motor, and cognitive disabilities, among others.',
        links: [
            {
                title: 'Web Content Accessibility Guidelines (WCAG)',
                url: 'https://www.w3.org/WAI/standards-guidelines/wcag/'
            },
            {
                title: 'Inclusive Design Principles',
                url: 'https://inclusivedesignprinciples.org/'
            }
        ]
    },
    14: {
        title: 'Design Experiences',
        desc: 'Design experiences involve creating products and services that provide meaningful and enjoyable experiences for users. This includes understanding user needs, designing for emotion, and creating intuitive and engaging interfaces.',
        links: [
            {
                title: 'The Elements of User Experience',
                url: 'https://www.uxdesigninstitute.com/blog/5-elements-of-ux-design/'
            },
            {
                title: 'The Importance of Emotional Design',
                url: 'https://octet.design/emotional-design-ux/'
            }
        ]
    },
    15: {
        title: 'Mood Boards',
        desc: 'A mood board is a collection of visual elements and design inspiration that designers use to communicate the overall aesthetic and tone of a project. Mood boards can include images, color palettes, typography, and other design elements.',
        links: [
            {
                title: 'How to Create a Mood Board',
                url: 'https://www.canva.com/learn/make-a-mood-board/'
            },
            {
                title: 'The Art of the Mood Board',
                url: 'https://www.masterclass.com/articles/how-to-make-a-moodboard-step-by-step-guide'
            }
        ]
    },
    16: {
        title: 'UX Learning Loop',
        desc: 'The UX learning loop is a framework for user-centered design that involves four stages: discover, define, develop, and deliver. This framework emphasizes the importance of understanding user needs, iterating designs based on user feedback, and continuously improving the user experience.',
        links: [
            {
                title: 'Core loops in UX',
                url: 'https://www.educative.io/answers/what-are-core-loops-in-ux'
            },
            {
                title: 'The missing link in your UX work: The UX Value Loop',
                url: 'https://www.youtube.com/watch?v=-XscLpLeFuI'
            }
        ]
    },
    17: {
        title: 'UX Research',
        desc: 'UX research involves gathering data and insights about users to understand their behaviors, needs, and motivations. This data is then used to inform the design of products that meet users\' needs and improve their experiences. UX research methods include user interviews, surveys, usability testing, and more.',
        links: [
            {
                title: 'A Beginner\'s Guide to UX Research',
                url: 'https://www.notion.so/A-Beginner-s-Guide-to-Getting-Started-in-UX-Research-1e3e0567b4944c938ae8d1a4f0a21c56',
            },
            {
                title: 'The Ultimate Guide to UX Research',
                url: 'https://www.interaction-design.org/literature/topics/ux-research',
            },
        ]
    },
    18: {
        title: 'Insight Translation',
        desc: 'Insight translation refers to the process of taking insights gained from user research and translating them into actionable design decisions. This involves distilling complex user data into clear and concise insights that can be easily understood and used by designers to inform their work.',
        links: [
            {
                title: 'Translating Research Insights into Design Actions',
                url: 'https://xd.adobe.com/ideas/process/user-research/translating-user-research-into-ux-design/',
            },
            {
                title: 'How to Translate User Research into UX Design',
                url: 'https://uxtools.co/blog/translating-user-research-to-design/',
            },
            {
                title: 'Using Insights to Drive Design Decisions',
                url: 'https://medium.com/neuron-ux/how-to-make-data-driven-product-design-decisions-1b089d76e1ec',
            }
        ]
    },
    19: {
        title: 'Ongoing Evaluation',
        desc: 'Ongoing evaluation refers to the process of continuously monitoring and assessing the effectiveness of a product or service throughout its lifecycle. This involves gathering feedback from users and analyzing data to identify areas for improvement and make informed design decisions.',
        links: [
            {
                title: 'Why Ongoing Evaluation is Critical for UX Design',
                url: 'https://enou.co/blog/how-to-evaluate-ux-design',
            },
            {
                title: 'The Importance of Ongoing Evaluation in UX Design',
                url: 'https://www.uxdesigninstitute.com/blog/why-ux-testing-is-so-important',
            },
            {
                title: 'The Role of Evaluation in UX Design',
                url: 'https://measuringu.com/evaluating-ux/',
            }
        ]
    }
}