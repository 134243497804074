import './App.css';
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import MainContainer from "./layout/MainContainer";

function App() {

  return (
    <div>
     <main>
        <Header />
        <MainContainer></MainContainer>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default App;
