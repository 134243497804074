export default {
    title: 'UX: Define',
    desc: 'The Define phase of the UX design process involves defining the problem, understanding user needs, and developing a plan to solve the problem.',
    1: {
        title: 'Site Maps',
        desc: 'Site maps are visual representations of a website’s architecture, which show the hierarchy of pages and how they are connected. They help designers to plan and organize the content of a website.',
        links: [
            {
                title: 'How to Create a Site Map: A Simple Guide', 
                url: 'https://uxdesign.cc/how-to-create-a-ux-sitemap-a-simple-guideline-8786c16f85c1',
            }
        ]
    },
    2: {
        title: 'Sketching',
        desc: 'Sketching is a quick and easy way to generate ideas and explore different design solutions. It helps designers to visualize and communicate their ideas to others.',
        links: [
            {
                title: 'The Power of Sketching in UX Design', 
                url: 'https://bootcamp.uxdesign.cc/the-power-of-sketching-f207c8d8ed2b',
            }
        ]
    },
    3: {
        title: 'Information Architecture',
        desc: 'Information architecture (IA) is the process of organizing, structuring, and labeling content in a way that makes it easy for users to find and navigate. It helps designers to create a clear and organized structure for a website or app.',
        links: [
            {
                title: 'The Beginner’s Guide to Information Architecture', 
                url: 'https://careerfoundry.com/en/blog/ux-design/a-beginners-guide-to-information-architecture/',
            }
        ]
    },
    4: {
        title: 'Analytics',
        desc: 'Analytics tools allow designers to gather data about how users interact with a website or app, which can help to identify areas for improvement. They provide insights into user behavior and can inform design decisions.',
        links: [
            {
                title: 'Google Analytics', 
                url: 'https://analytics.google.com/analytics/web/',
            }
        ]
    },
    5: {
        title: 'Usability Testing',
        desc: 'Usability testing involves observing users as they interact with a website or app, in order to identify usability issues and gather feedback. It helps designers to understand how users interact with their design and make improvements.',
        links: [
            {
                title: 'The Ultimate Guide to Usability Testing', 
                url: 'https://www.uxpin.com/studio/ebooks/guide-to-usability-testing/',
            }
        ]
    },
    6: {
        title: 'Clickable Prototypes',
        desc: 'Clickable prototypes allow designers to create interactive mockups of a website or app, which can be used to test and refine the design. They provide a more realistic and interactive experience for users.',
        links: [
            {
                title: 'Prototyping in Figma', 
                url: 'https://help.figma.com/hc/en-us/articles/360040314193-Guide-to-prototyping-in-Figma',
            }
        ]
    },
    7: {
        title: 'Four-Up',
        desc: 'Four-Up is a brainstorming exercise in which designers generate four different design concepts in a short amount of time. It helps to encourage creativity and generate a range of ideas.'
    },
    8: {
        title: 'Crazy-Eights',
        desc: 'Crazy-Eights is a fast-paced ideation exercise that encourages participants to generate as many ideas as possible in a short amount of time. It\'s a great way to quickly come up with a lot of different ideas and concepts. To play Crazy-Eights, you need a group of people, a whiteboard or large piece of paper, and some markers or pens. The goal is to come up with as many ideas as possible in eight minutes. The more creative and unconventional the ideas, the better!',
        links: [
            {
                title: 'Crazy Eights: A Simple Exercise to Boost Your Creativity',
                url: 'https://conceptboard.com/blog/crazy-8s-brainstorming-template/'
            },
            {
                title: 'Crazy Eights: How to Play the Game, Rules and Scoring',
                url: 'https://miro.com/templates/crazy-eights/'
            }
        ]
    }
}