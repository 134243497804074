import React from "react";
import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={classes.layoutFooter}>
      <p>Keen on mastering UX/UI Design in a free self-paced bootcamp? </p>
            <a href="https://www.uxuiopen.com/" target="_blank">
                <button>Learn more</button>
            </a>
    </footer>
  );
};

export default Footer;
