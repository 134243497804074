export default {
    title: 'Design Delivery',
    desc: 'Design delivery is the process of finalizing designs and preparing them for handoff to developers or other stakeholders. This involves ensuring that all necessary design assets and documentation are complete and organized in a way that is easy to understand and use.',
    1: {
        title: 'Summarize Findings',
        desc: 'After conducting user research, it is important to summarize your findings in a clear and concise manner. This helps ensure that all team members have a shared understanding of the research insights and can use them to inform design decisions.',
        links: [
            {
                title: 'A complete guide to presenting UX research findings',
                url: 'https://www.uxdesigninstitute.com/blog/presenting-ux-research-findings/',
            },
            {
                title: 'User Research Analysis and Synthesis Techniques',
                url: 'https://www.userinterviews.com/ux-research-field-guide-module/research-analysis-synthesis',
            }
        ]
    },
    2: {
        title: 'Presenting To Stakeholders',
        desc: 'When presenting design work to stakeholders, it is important to communicate the design decisions and their rationale clearly and effectively. This helps build buy-in and support for the design and ensures that stakeholders have a shared understanding of the project goals.',
        links: [
            {
                title: 'Tips for Presenting Design Work to Non-Designers',
                url: 'https://uxdesign.cc/how-to-present-design-work-to-non-designers-d0cb60ebe21',
            },
            {
                title: 'How to Present Design Work Like a Pro',
                url: 'https://www.holabrief.com/blog/how-to-present-design-work-like-a-pro',
            },
            {
                title: 'Tips for Presenting UX Work',
                url: 'https://dribbble.com/stories/2019/07/22/11-tips-for-pitching-your-ui-ux-designs-to-non-designers',
            }
        ]
    },
    3: {
        title: 'Planning For Development',
        desc: 'In order to ensure a smooth handoff from design to development, it is important to plan for development during the design phase. This involves considering technical constraints and opportunities when making design decisions and creating design assets that are easy to implement in code.',
        links: [
            {
                title: 'Designing for Developers',
                url: 'https://evilmartians.com/chronicles/devs-in-mind-how-to-design-interfaces-for-developer-tools',
            },
            {
                title: 'Design Handoff Checklist: What Your Developer Needs to Succeed',
                url: 'https://www.uxpin.com/studio/blog/design-handoff-checklist/',
            },
        ]
    },
    4: {
        title: 'Webflow, Bubble',
        desc: 'Webflow and Bubble are both no-code platforms for creating websites and web applications. Webflow is known for its visual design interface and powerful CMS, while Bubble offers more advanced functionality and allows for custom coding. Both platforms are great options for those without coding experience who still want to build high-quality web projects.',
        links: [
            {
                title: 'Webflow',
                url: 'https://webflow.com/',
            },
            {
                title: 'Bubble',
                url: 'https://bubble.io/',
            },
            {
                title: 'Webflow vs. Bubble: Which One Is Better for Your Project?',
                url: 'https://brycevernon.com/webflow-vs-bubble/',
            }
        ]
    },
    5: {
        title: 'UI Elements',
        desc: 'UI elements are the individual building blocks that make up a user interface. Examples include buttons, forms, menus, and icons. By using a library of pre-made UI elements, designers and developers can save time and ensure consistency in their projects.',
        links: [
            {
                title: 'Material UI',
                url: 'https://mui.com/',
            },
            {
                title: 'Bootstrap',
                url: 'https://getbootstrap.com/',
            },
            {
                title: '10 Awesome UI Kits for Your Next Project',
                url: 'https://dribbble.com/resources/free-ui-kits',
            }
        ]
    },
    6: {
        title: 'UI Library',
        desc: 'A UI library is a collection of pre-made user interface elements, often with a consistent design language and style. Using a UI library can save designers and developers time and ensure consistency in their projects. Some popular UI libraries include Material UI, Bootstrap, and Semantic UI.',
        links: [
            {
                title: '10 Awesome UI Kits for Your Next Project',
                url: 'https://blog.tubikstudio.com/10-awesome-ui-kits-for-your-next-project/',
            },
            {
                title: 'How to Use UI Kits to Speed Up Your Design Workflow',
                url: 'https://www.creativebloq.com/ux/5-ways-speed-your-design-ui-kits-11618753',
            }
        ]
    },
    7: {
        title: 'Management',
        desc: 'Management refers to the process of planning, organizing, leading, and controlling resources (including people, finances, and materials) to achieve organizational goals. In the context of UX/UI design, management skills can be particularly important for overseeing projects, coordinating team members, and ensuring timely delivery of high-quality work.',
        links: [
            {
                title: 'The Lean Product Playbook',
                url: 'https://leanproductplaybook.com/',
            },
            {
                title: 'UX Management: Strategy and Tactics',
                url: 'https://www.interaction-design.org/courses/ux-management-strategy-and-tactics',
            },
            {
                title: 'The Manager\'s Path: A Guide for Tech Leaders Navigating Growth and Change',
                url: 'https://www.amazon.com/Managers-Path-Leaders-Navigating-Growth/dp/1491973897',
            }
        ]
    },
    8: {
        title: 'Client Alignment',
        desc: 'Client alignment refers to the process of ensuring that the goals and expectations of a client are in line with the goals and expectations of a design team. This can involve regular communication, setting clear objectives and deliverables, and managing expectations throughout the project lifecycle.',
        links: [
            {
                title: 'How to Align UX with Business Goals When Everyone is a Client',
                url: 'https://www.galaxyweblinks.com/aligning-ux-strategy-with-business-goals/',
            }
        ]
    },
    9: {
        title: 'Analytics',
        desc: 'Analytics refers to the process of collecting, measuring, and analyzing data to understand user behavior and improve the effectiveness of a product or service. In UX/UI design, analytics can be used to evaluate user engagement, identify pain points, and measure the success of design changes.',
        links: [
            {
                title: 'Google Analytics',
                url: 'https://analytics.google.com/',
            },
            {
                title: 'Hotjar',
                url: 'https://www.hotjar.com/',
            },
            {
                title: 'How to Use Analytics to Improve User Experience',
                url: 'https://jetpack.com/blog/user-experience-website-analytics/',
            },
            {
                title: 'The Ultimate Guide to Web and App User Attribution',
                url: 'https://www.branch.io/resources/blog/the-ultimate-guide-to-web-and-app-user-attribution-best-practices-cross-platform-cross-channel-2018/',
            }
        ]
    },
    10: {
        title: 'Case Study',
        desc: 'A case study is a detailed examination of a particular situation or project, often used to demonstrate the effectiveness of a particular solution or approach. In UX/UI design, case studies can be particularly useful for showcasing past work, highlighting successes and challenges, and providing insights into the design process.',
        links: [
            {
                title: 'Why you should be writing UX case studies',
                url: 'https://uxplanet.org/why-you-should-be-writing-ux-case-studies-6827a3b25a56',
            },
            {
                title: 'Top 10 UX Case Studies Every Designer Should Read',
                url: 'https://mockitt.wondershare.com/ui-ux-design/ux-case-studies.html',
            },
        ]
    },
    11: {
        title: 'Usability Evaluation',
        desc: 'Usability evaluation is the process of evaluating a product or system for ease of use and effectiveness. This process typically involves user testing, heuristic evaluation, and expert reviews. For more information on usability evaluation techniques, check out the resources below:',
        links: [
            {
                title: 'Usability.gov',
                url: 'https://www.usability.gov/'
            },
            {
                title: 'Nielsen Norman Group',
                url: 'https://www.nngroup.com/'
            },
        ]
    },
    12: {
        title: 'Product Metrics',
        desc: 'Product metrics are used to measure the success of a product or feature. These metrics can include things like user engagement, retention, and conversion rates. Here are some resources for learning more about product metrics:',
        links: [
            {
                title: 'Mixpanel',
                url: 'https://mixpanel.com/'
            },
            {
                title: 'Google Analytics',
                url: 'https://analytics.google.com/'
            },
            {
                title: 'Neil Patels personal website',
                url: 'https://neilpatel.com/'
            }
        ]
    }
}