export default {
    title: 'User Interface',
    desc: 'User Interface (UI) is the visual and interactive part of a product that users interact with. A good UI should be easy to use, intuitive, and aesthetically pleasing.',
    1: {
        title: 'Mental Models',
        desc: 'Mental models are a representation of a user\'s understanding of how a system or product works. Designers can use mental models to create interfaces that are more intuitive and easier to use.',
        links:  [
            {
                title: 'The Importance of Mental Models in UI Design',
                url: 'https://www.nngroup.com/articles/mental-models/',
            },
            {
                title: 'Mental Models in User Experience Design',
                url: 'https://xd.adobe.com/ideas/process/ui-design/how-to-use-mental-models-in-ux-design/',
            }
        ]
    },
    2: {
        title: 'Design for Emotion',
        desc: 'Design for emotion is the practice of using design to elicit specific emotional responses from users. By understanding how design elements can impact emotions, designers can create interfaces that are more engaging and memorable.',
        links:  [
            {
                title: 'Design for Emotion: How to Create More Delightful User Experiences',
                url: 'https://uxdesign.cc/designing-for-emotional-delight-4c96e0bf00f4',
            },
            {
                title: 'Designing for Emotion',
                url: 'https://uxplanet.org/design-for-emotion-expert-tips-by-aarron-walter-2f847e75a962',
            }
        ]
    },
    3: {
        title: 'Design Principles',
        desc: 'Design principles are fundamental concepts that guide the design of interfaces. By adhering to design principles, designers can create interfaces that are more intuitive, effective, and user-friendly.',
        links:  [
            {
                title: '10 Principles Of Good UI Design To Follow On Every Web Design Project',
                url: 'https://www.interaction-design.org/literature/article/user-interface-design-guidelines-10-rules-of-thumb',
            },
            {
                title: 'The Ultimate Guide to Basic Design Principles for Web Designers',
                url: 'https://webdesign.tutsplus.com/articles/web-design-for-beginners-epic-free-course--cms-41118',
            }
        ]
    },
    4: {
        title: 'HiFi Prototypes',
        desc: 'High-fidelity (HiFi) prototypes are interactive and realistic representations of a product that simulate the final product experience. HiFi prototypes can be used to test and refine interface designs before they are implemented.',
        links: [
            {
                title: 'What are Hi-Fi Prototypes and Why are They Important?',
                url: 'https://mockitt.wondershare.com/prototyping/high-fidelity-prototypes.html',
            },
            {
                title: 'Create High Fidelity Designs and Prototypes in Figma',
                url: 'https://www.youtube.com/watch?v=87shZMjA4bs',
            }
        ]
    },
    5: {
        title: 'Mockups',
        desc: 'Mockups are realistic visualizations of a digital or physical product, used to showcase design ideas and test their functionality. They are an important part of the design process as they allow designers to identify potential issues and make necessary changes before the final product is developed.',
        links: [
            {
                title: 'How to create mockups in Adobe XD',
                url: 'https://www.youtube.com/watch?v=_J-hNVcatvI',
            },
            {
                title: '10 best mockup tools for designers',
                url: 'https://www.browserstack.com/guide/top-website-mockup-tools',
            }
        ]
    },
    6: {
        title: 'Animations',
        desc: 'Animations are an effective way to enhance the user experience by providing feedback, guiding the user, and adding visual interest to the design. Animations can be used for various purposes like loading indicators, hover effects, scrolling effects, and more.',
        links: [
            {
                title: 'Awesome Micro Animations With Figma',
                url: 'https://www.youtube.com/watch?v=0YIovQXnwBY',
            },
            {
                title: 'Figma Advanced Animations',
                url: 'https://www.youtube.com/watch?v=20IfkyJigKg',
            },
            {
                title: 'Lottie - Animation library for Web, Android, and iOS',
                url: 'https://airbnb.design/lottie/',
            }
        ]
    },
    7: {
        title: 'Typography',
        desc: 'Typography is the art and technique of arranging type to make written language legible, readable, and appealing when displayed. Good typography can enhance the readability and visual appeal of a design and communicate the intended message more effectively.',
        links: [
            {
                title: 'Figma Tutorial: Create the Perfect Typography Scale for UI & Web ',
                url: 'https://youtu.be/6EuBIwUeF48',
            },
            {
                title: 'Typography in UI design',
                url: 'https://www.interaction-design.org/literature/article/the-ux-designer-s-guide-to-typography',
            },
            {
                title: 'Google Fonts - Free, open-source fonts optimized for the web',
                url: 'https://fonts.google.com/',
            }
        ]
    },
    8: {
        title: 'Color Palette',
        desc: 'Color palette is a collection of colors used in a design to create a visual identity and convey the intended message. Choosing the right color palette is important as it can affect the mood, emotions, and perception of the users towards the design.',
        links: [
            {
                title: '60-30-10 Color Rule',
                url: 'https://www.youtube.com/watch?v=UWwNIMHFdW4',
            },
            {
                title: 'Color theory for designers',
                url: 'https://www.canva.com/colors/color-wheel/',
            },
            {
                title: 'Coolors - Color scheme generator and picker',
                url: 'https://coolors.co/',
            }
        ]
    },
    9: {
        title: 'Logo Development',
        desc: 'Logo development involves designing a unique and memorable symbol or emblem that represents a brand or organization. A well-designed logo helps to build brand recognition and credibility. It typically involves research, sketching, and iterating on ideas before arriving at a final design. Consider using tools such as Adobe Illustrator or Sketch to create professional logos.',
        links: [
            {
                title: 'Logo Design in Figma',
                url: 'https://youtu.be/c9Wg6Cb_YlU',
            },
            {
                title: 'Logo Design Process: A Guide From Start To Finish',
                url: 'https://www.designyup.com/logo-design-process-from-start-to-finish/',
            },
            {
                title: 'How to Design a Logo: Full Process From Start to Finish',
                url: 'https://www.youtube.com/watch?v=TF4_T7yMTOA',
            }
        ]
    },
    10: {
        title: 'Design Hierarchy',
        desc: 'Design hierarchy is the arrangement of visual elements in order of importance. It helps to guide the viewer\'s attention and create a clear hierarchy of information. Elements such as size, color, contrast, and typography can be used to create a hierarchy. Paying attention to hierarchy is important in creating effective designs that communicate a message clearly.',
        links: [
            {
                title: 'The Ultimate Guide to Design Hierarchy',
                url: 'https://www.canva.com/learn/visual-hierarchy/',
            },
            {
                title: 'Design Principles: Visual Hierarchy',
                url: 'https://www.interaction-design.org/literature/topics/visual-hierarchy',
            },
            {
                title: 'Hierarchy in Web Design: A Beginner’s Guide',
                url: 'https://webdesigndev.com/beginner-designers-guide-visual-hierarchy/',
            }
        ]
    },
    11: {
        title: 'Space',
        desc: 'Space, or negative space, refers to the empty areas around and between design elements. It can be used to create visual balance, establish a sense of hierarchy, and guide the viewer\'s eye. Careful use of space can help to create a harmonious and effective design. Experiment with different spacing options to find what works best for your design.',
        links: [
            {
                title: 'The Power of White Space',
                url: 'https://uxplanet.org/the-power-of-whitespace-a1a95e45f82b',
            },
            {
                title: 'Negative Space in Design: Tips & Examples',
                url: 'https://blog.tubikstudio.com/negative-space-in-design-tips-and-best-practices-2/',
            },
            {
                title: 'The Essence of Space in Design',
                url: 'https://uxdesign.cc/the-essence-of-space-in-design-b71f42166b82',
            }
        ]
    },
    12: {
        title: 'Grid System',
        desc: 'A grid system is a layout tool used to create consistent and organized designs. It divides the screen into columns and rows, allowing designers to align and place elements on a page. Grid systems help create balance and harmony in designs and make them easier to read and navigate. To learn more about grid systems, check out these resources:',
        links: [
          {
            title: 'Figma: Grid Systems',
            url: 'https://www.youtube.com/watch?v=_w-iYiEXrl0&t=92s',
          },
          {
            title: 'How to Create a Grid System for Your Designs',
            url: 'https://www.youtube.com/watch?v=2xP6TBnfP7M',
          },
          {
            title: 'Grid Systems in Graphic Design: A Visual Communication Manual for Graphic Designers, Typographers and Three Dimensional Designers',
            url: 'https://www.amazon.com/Grid-Systems-Graphic-Design-Communication/dp/3721201450',
          },
        ]
      },
      13: {
        title: 'Design Systems',
        desc: 'A design system is a collection of reusable components, guidelines, and best practices used to create a consistent and cohesive user interface. Design systems help teams work more efficiently, reduce development costs, and improve user experience. To learn more about design systems, check out these resources:',
        links: [
          {
            title: 'What is a Design System? And Why Do You Need One?',
            url: 'https://www.nngroup.com/articles/design-systems-101/',
          },
          {
            title: 'Design Systems Handbook',
            url: 'https://www.designbetter.co/design-systems-handbook',
          },
          {
            title: 'Create a Figma Design System',
            url: 'https://www.youtube.com/watch?v=G1xmkQeExJo',
          },
        ]
      },
      14: {
        title: 'Adobe',
        desc: 'Adobe is a software company that provides creative tools and services for designers, photographers, and videographers. Adobe\'s products include Photoshop, Illustrator, InDesign, Premiere Pro, and more. To learn more about Adobe and its products, check out these resources:',
        links: [
          {
            title: 'Adobe Creative Cloud Website',
            url: 'https://www.adobe.com/creativecloud.html',
          },
        ]
      },
      15: {
        title: 'Sketch',
        desc: 'Sketch is a design tool used to create user interfaces, icons, and other graphic elements. It\'s popular among UX/UI designers and has a range of features and plugins to enhance design workflow. To learn more about Sketch and its capabilities, check out these resources:',
        links: [
          {
            title: 'Sketch Website',
            url: 'https://www.sketch.com/',
          },
        ]
      },
      16: {
        title: 'Figma',
        desc: 'Figma is a cloud-based design and prototyping tool that allows teams to collaborate on designs in real-time. It\'s similar to Sketch and Adobe XD, but with the added benefit of being able to collaborate with others in real-time. Figma also has robust design and prototyping features, making it a popular choice for designers and teams alike.',
        links: [
            {
                title: 'Figma Website',
                url: 'https://www.figma.com/'
            },
            {
                title: 'Free Figma UX Design UI Essentials Course',
                url: 'https://youtu.be/kbZejnPXyLM'
            },
            {
                title: 'Figma Tips and Tricks',
                url: 'https://youtu.be/QcbtVChzVrI'
            }
        ]
    },
    17: {
        title: 'Atomic Design',
        desc: 'Atomic Design is a methodology for creating design systems. It breaks down interfaces into smaller, reusable parts called atoms, which can then be combined to create more complex components. By designing in this way, you can create more consistent and scalable designs. Brad Frost, who coined the term, has a great blog post on the topic.',
        links: [
            {
                title: 'Atomic Design Methodology',
                url: 'https://atomicdesign.bradfrost.com/chapter-2/'
            },
            {
                title: 'How we use the Atomic Design Method in Figma ',
                url: 'https://youtu.be/TlKIy3Sm_lo'
            },
            {
                title: 'Atomic Design in Sketch',
                url: 'https://youtu.be/D3eVHGTIzTI'
            }
        ]
    },
    18: {
        title: 'Responsive Design',
        desc: 'Responsive Design is an approach to designing websites and applications that adapt to different screen sizes and devices. This means that your site will look good and function properly on everything from a large desktop monitor to a small smartphone screen. Ethan Marcotte is credited with coining the term, and his book on the topic is a great resource.',
        links: [
            {
                title: 'A List Apart: Responsive Web Design',
                url: 'https://www.interaction-design.org/literature/topics/responsive-design'
            },
            {
                title: 'Responsive Design Patterns',
                url: 'https://bradfrost.github.io/this-is-responsive/patterns.html'
            },
            {
                title: 'Responsive Web Design Basics',
                url: 'https://youtu.be/GCe3ONm23ME'
            }
        ]
    },
    19: {
        title: 'Inclusive Design',
        desc: 'Inclusive Design is the practice of designing products and services that can be used by as many people as possible, regardless of their abilities or disabilities. This means designing with accessibility in mind, but it also means designing for a diverse range of users. There are many resources available to help you learn more about inclusive design and how to implement it in your work.',
        links: [
            {
                title: 'Inclusive Design Principles',
                url: 'https://inclusivedesignprinciples.org/'
            },
            {
                title: 'Accessibility Guidelines (WCAG)',
                url: 'https://www.w3.org/WAI/standards-guidelines/wcag/'
            },
            {
                title: 'Inclusive Design Toolkit',
                url: 'https://www.inclusivedesigntoolkit.com/'
            },
            {
                title: 'A Designer’s Guide to Accessibility',
                url: 'https://stephaniewalter.design/blog/a-designers-guide-to-documenting-accessibility-user-interactions/'
            }
        ]
    },
    20: {
        title: 'Design Patterns',
        desc: 'Design Patterns are solutions to recurring design problems that can be applied to different contexts. They help designers and developers create more efficient and scalable interfaces by using tried and tested approaches. There are many design pattern libraries and resources available online.',
        links: [
            {
                title: 'UI Patterns',
                url: 'https://ui-patterns.com/'
            },
            {
                title: 'Material Design Patterns',
                url: 'https://material.io/design/patterns/'
            },
            {
                title: 'iOS Human Interface Guidelines',
                url: 'https://developer.apple.com/design/human-interface-guidelines/'
            }
        ]
    }
}